// Handle updating worklist with study status changes on server.
//
import Vue from 'vue'
import axios from 'axios'
import store from '../store'
import webServices from '../common/webServices'

function handleQuery(oneTime = false) {
    try {
        const ts = store.state.studyStatusTs
        axios.get('/StudyStatusChanges/'+encodeURIComponent(ts), {
            baseURL: store.state.webServicesBaseUrl,
            headers: {
                Authorization: 'Bearer '+store.state.keycloak.token
            }
        })
        .then(response => {
            store.state.studyStatusTs = response.data.ts
            const userId = store.state.keycloak.tokenParsed.sub
            for (var s = 0; s < response.data.studies.length; s++) {
                const study = response.data.studies[s]
                
                // Find entry if in worklist and update
                //
                if (store.state.worklistRefreshing == '') {
                    let dirty = false
                    for (let w = 0; w < store.state.worklist.length; w++) {
                        let entry = store.state.worklist[w]
                        if (entry.study_uid == study.uid) {
                            entry.read = study.r
                            entry.stat = study.s
                            entry.lock = study.l
                            entry.lufn = study.lufn
                            entry.locked = (study.l != '')

                            // Update other values based on above.
                            // +TODO+ Handle n2r for in transit studies?
                            // +TODO+ Handle read flag.
                            //
                            const lockedByAnother = ((study.l != '') && (study.l != userId))
                            entry._rowVariant = (lockedByAnother) ? '' : webServices.reportStatusToVariant(entry.report_status, study.r, study.s)
                            dirty = true
                            break
                        }
                    }
                    if (dirty) {
                        store.commit('changeWorklist', store.state.worklist)
                    }
                }

                if (!store.state.mgmtReportRefreshing) {
                    // Find entry if in mgmt report and update
                    //
                    let dirty = false
                    for (let w = 0; w < store.state.mgmtReportItems.length; w++) {
                        let entry = store.state.mgmtReportItems[w]
                        if (entry._study_iuid == study.uid) {
                            entry._r = study.r
                            entry._s = study.s
                            entry._l = study.l
    
                            // Update other values based on above.
                            // +TODO+ Handle read flag.
                            //
                            const lockedByAnother = ((study.l != '') && (study.l != userId))
                            entry._rowVariant = (lockedByAnother) ? '' : webServices.reportStatusToVariant(entry['Report Status'], study.r, study.s)
                            break
                        }
                    }
                    if (dirty) {
                        store.commit('changeMgmtReportItems', store.state.mgmtReportItems)
                    }
                }
            }
        })
        .catch(err => {
            Vue.$log.error("Error: "+err.message)
        })
        .finally(() => {
            if (!oneTime) {
                queueQuery()
            }
        })


    } catch (err) {
        Vue.$log.error(`studyStatusChanges::handleQuery error: ${err.message}`)
    }
}

// Handle setting up study status updater.
//
function queueQuery() {
    let timeout = 15
    try {
        timeout = Vue.$configs.studyStatusIntvlSec || 60
    }
    catch {
        timeout = 15
    }
    setTimeout(() => {
      handleQuery()
    }, timeout * 1000);
}

export default {
    handleQuery,
    queueQuery
}