// Handle inter-app communications between tabs/windows.
//
import Vue from "vue";
import store from '../store'

// Message types...
//
const CLOSE_REPORT_WINDOW_MSG = 'CloseReportWindow'
const CLOSE_STUDY_MSG = 'CloseStudyMsg'
const CLOSE_VIEWER_WINDOW_MSG = 'CloseViewerWindow'
const NEXT_STUDY_UID_MSG = 'NextStudyUidMsg'
const OPEN_REPORT_WINDOW_MSG = 'OpenReportWindow'
const OPEN_VIEWER_WINDOW_MSG = 'OpenViewerWindow'
const PREV_STUDY_UID_MSG = 'PrevStudyUidMsg'
const REFRESH_WORKLIST_MSG = 'RefreshWorklist'
const REPORT_WINDOW_CLOSED_MSG = 'ReportWindowClosed'
const REPORT_WINDOW_EDITING_MSG = 'ReportWindowEditing'
const REPORT_WINDOW_READY_MSG = 'ReportWindowReady'
const REPORT_WINDOW_RESIZE_MSG = 'ReportWindowResize'
const REPORT_WINDOW_VIEWING_MSG = 'ReportWindowViewing'
const SELECTED_STUDY_MSG = 'SelectedStudy'
const STUDY_UPDATED_MSG = 'StudyUpdated'
const VIEWER_WINDOW_CLOSED_MSG = 'ViewerWindowClosed'
const VIEWER_WINDOW_READY_MSG = 'ViewerWindowReady'
const VIEWER_WINDOW_RESIZE_MSG = 'ViewerWindowResize'
const WORKLIST_NEXT_PREV_MSG = 'WorklistNextPrevMsg'

let channel = new BroadcastChannel('SaincePacsMessageChannel')

function postMessage(type, payload='') {
    let msg = {
        type: type,
        payload: payload
    }
    try {
        Vue.$log.debug('Posting message type='+type)
        channel.postMessage(msg)
    }
    catch(error) {
        Vue.$log.error('Error posting message: '+error.message)
    }
    return
}

function postSelectedStudy(target, windowUid = '') {

    let payload = store.getters.worklistEntryForSelectedStudyUid(windowUid)
    payload['target'] = target
    payload['windowUid'] = windowUid
    postMessage(SELECTED_STUDY_MSG, payload)
    payload = {
      'nextStudyUid': store.getters.nextStudyUid(store.getters.selectedStudyUid(windowUid)),
      'previousStudyUid': store.getters.previousStudyUid(store.getters.selectedStudyUid(windowUid)),
      'target': target,
      'windowUid': windowUid
    }
    postMessage(WORKLIST_NEXT_PREV_MSG, payload)
}

export default {
    // constants
    CLOSE_REPORT_WINDOW_MSG,
    CLOSE_STUDY_MSG,
    CLOSE_VIEWER_WINDOW_MSG,
    NEXT_STUDY_UID_MSG,
    OPEN_REPORT_WINDOW_MSG,
    OPEN_VIEWER_WINDOW_MSG,
    PREV_STUDY_UID_MSG,
    REFRESH_WORKLIST_MSG,
    REPORT_WINDOW_CLOSED_MSG,
    REPORT_WINDOW_EDITING_MSG,
    REPORT_WINDOW_READY_MSG,
    REPORT_WINDOW_RESIZE_MSG,
    REPORT_WINDOW_VIEWING_MSG,
    SELECTED_STUDY_MSG,
    STUDY_UPDATED_MSG,
    VIEWER_WINDOW_CLOSED_MSG,
    VIEWER_WINDOW_READY_MSG,
    VIEWER_WINDOW_RESIZE_MSG,
    WORKLIST_NEXT_PREV_MSG,

    // objects
    channel,

    // functions
    postMessage,
    postSelectedStudy
}