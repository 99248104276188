// DICOMweb helper functions
//
import axios from 'axios'
import uuid from 'uuid'
import Vue from 'vue'
import store from '../store'
import webServices from './webServices'

// Get value from DICOMweb JSON entry.
//
function getValue(entry, tag, index=0) {
    var value = ''
    try {
        value = entry[tag].Value[index]
    }
    catch(err) {
        value = '';
    }
    return value
}

function getDecimalValue(entry, tag, defaultValue = 0, index = 0) {
    var value = NaN
    try {
        value = parseFloat(getValue(entry, tag, index))
    }
    catch {
        value = NaN
    } 
    return isNaN(value) ? defaultValue : value
}

function getIntegerValue(entry, tag, defaultValue = 0, index = 0) {
    var value = NaN
    try {
        value = parseInt(getValue(entry, tag, index), 10)
    }
    catch {
        value = NaN
    } 
    return isNaN(value) ? defaultValue : value
}

async function downloadStudy(worklistEntry) {
    if (worklistEntry != null) {
        const aet = store.getters.aetForGroup('/'+worklistEntry['group'])
        let token = await webServices.createDicomwebToken(aet, worklistEntry.study_uid)
        const link = document.createElement('a')
        link.href = `${store.state.webServicesBaseUrl}/ShareStudyArchive/${token}`
        link.click()
    }
}

function getPixelData(worklistEntry, seriesUid, instanceUid, frame) {
    return new Promise((resolve, reject) => {
        if (worklistEntry != null) {
            const aet = store.getters.aetForGroup('/'+worklistEntry['group'])
            var thumbnailUrl = store.state.webServicesBaseUrl + '/DicomWebProxy/' + aet +
                "/rs/studies/"+worklistEntry.study_uid+"/series/" + seriesUid + "/instances/" + instanceUid +
                "/frames/" + encodeURIComponent(frame) + 
                "?accept=" + encodeURIComponent("multipart/related;type=application/octet-stream")
            axios.get(thumbnailUrl, {
                headers: {
                    Authorization: 'Bearer '+store.state.keycloak.token
                },
                responseType: 'arraybuffer'
            }).then(response => {
                // Handle splitting apart multipart response.
                //
                let header = response.headers['content-type'];
                let boundary = "--"+header.replace(/.* boundary=/, "");
                let boundaryN = boundary.length + 4
                let boundaryI = response.data.byteLength - boundaryN
                var dataView = new DataView(response.data);
                var decoder = new TextDecoder('utf-8');
                const searchStr = "Content-Type: application/octet-stream"
                var contentI = decoder.decode(dataView).indexOf(searchStr, boundaryN) + searchStr.length + 4
                resolve(response.data.slice(contentI, boundaryI))
            })
            .catch(err => {
                Vue.$log.error("webServices error: "+err.message)
                reject(err)
            })
        }
        else {
            reject(new Error('worklistEntry null'))
        }
    })
}

function getPixelDataRendered(worklistEntry, seriesUid, instanceUid, frame, quality) {
    return new Promise((resolve, reject) => {
        if (worklistEntry != null) {
            const aet = store.getters.aetForGroup('/'+worklistEntry['group'])
            var thumbnailUrl = store.state.webServicesBaseUrl + '/DicomWebProxy/' + aet +
                "/rs/studies/"+worklistEntry.study_uid+"/series/" + seriesUid + "/instances/" + instanceUid +
                "/frames/" + encodeURIComponent(frame) + 
                "/rendered?accept=" + encodeURIComponent("multipart/related;image/jpeg") +
                "&quality=" + encodeURIComponent(quality)
            axios.get(thumbnailUrl, {
                headers: {
                    Accept: "multipart/related;image/jpeg",
                    Authorization: 'Bearer '+store.state.keycloak.token
                },
                responseType: 'arraybuffer'
            }).then(response => {
                // Handle splitting apart multipart response.
                //
                let header = response.headers['content-type'];
                let boundary = "--"+header.replace(/.* boundary=/, "");
                let dataView = new DataView(response.data);
                let dataViewN = response.data.byteLength
                let dataViewI = 0
                let jfifI = -1
                while((jfifI == -1) && ((dataViewI+10) < dataViewN)) {
                    // Search for JFIF (JPEG File Interchange Format) file header.
                    // Ref: https://docs.fileformat.com/image/jfif/
                    //
                    if ((dataView.getUint8(dataViewI) == 0xff) && (dataView.getUint8(dataViewI+1) == 0xd8) &&
                        (dataView.getUint8(dataViewI+2) == 0xff) && (dataView.getUint8(dataViewI+3) == 0xe0) &&
                        (dataView.getUint8(dataViewI+6) == 0x4a) && (dataView.getUint8(dataViewI+7) == 0x46) && 
                        (dataView.getUint8(dataViewI+8) == 0x49) && (dataView.getUint8(dataViewI+9) == 0x46) &&
                        (dataView.getUint8(dataViewI+10) == 0)) {
                        jfifI = dataViewI
                        break;
                    }
                    dataViewI++
                }
                // +TODO+ Find end of JPEG by searching for boundary value.
                // +TODO+ Handle multiple JPEGs
                let boundaryN = boundary.length + 4
                let boundaryI = -1
                boundaryI = dataViewN - boundaryN
                if ((jfifI != -1) && (boundaryI != -1))
                {
                    resolve(response.data.slice(jfifI, boundaryI))
                }
                reject('unable to parse JPEG image')
            })
            .catch(err => {
                Vue.$log.error("webServices error: "+err.message)
                reject(err)
            })
        }
        else {
            reject(new Error('worklistEntry null'))
        }
    })
}

function getThumbnail(worklistEntry, seriesUid, instanceUid) {
    return new Promise((resolve, reject) => {
        if (worklistEntry != null) {
            const aet = store.getters.aetForGroup('/'+worklistEntry['group'])
            var thumbnailUrl = store.state.webServicesBaseUrl + '/DicomWebProxy/' + aet +
                "/rs/studies/"+worklistEntry.study_uid+"/series/" + seriesUid + "/instances/" + instanceUid +
                "/thumbnail?accept=" + encodeURIComponent("image/jpeg")
            axios.get(thumbnailUrl, {
                headers: {
                    Authorization: 'Bearer '+store.state.keycloak.token
                },
                responseType: 'arraybuffer'
            }).then(response => {
                resolve(response.data)
            })
            .catch(err => {
                Vue.$log.error("webServices error: "+err.message)
                reject(err)
            })
        }
        else {
            reject(new Error('worklistEntry null'))
        }
    })
}

function getInstanceMetadata(worklistEntry, seriesUid, instanceUid) {
    return new Promise((resolve, reject) => {
        var instanceMetadata = {}
        if (worklistEntry != null) {
            const aet = store.getters.aetForGroup('/'+worklistEntry['group'])
            const wadoUrl = store.state.webServicesBaseUrl + '/DicomWebProxy/' + aet +
                "/rs/studies/"+worklistEntry.study_uid+"/series/" + seriesUid + "/instances/" + 
                instanceUid + "/metadata" +
                "?includefield=20500020"
            axios.get(wadoUrl, {
                headers: {
                    Authorization: 'Bearer '+store.state.keycloak.token
                }
            })
            .then(result => {
                if (result.data.length > 0) {
                    instanceMetadata = result.data[0]
                }
                resolve(instanceMetadata)
            })
            .catch(e => {
                Vue.$log.error("dicomweb wado error: "+e.message)
                reject(e)
            })
        }
        else {
            reject(new Error('worklistEntry null'))
        }
    })
}

function getInstances(worklistEntry, seriesUid) {
    return new Promise((resolve, reject) => {
        var instanceInfo = []
        if (worklistEntry != null) {
            const aet = store.getters.aetForGroup('/'+worklistEntry['group'])
            const qidoUrl = store.state.webServicesBaseUrl + '/DicomWebProxy/' + aet +
                "/rs/studies/"+worklistEntry.study_uid+"/series/" + seriesUid + "/instances?orderby=00200013" +
                "&includefield=00280002&includefield=00280008"
            axios.get(qidoUrl, {
                headers: {
                    Authorization: 'Bearer '+store.state.keycloak.token
                }
            })
            .then(result => {
                for (var i=0; i<result.data.length; i++) {
                    const instance = result.data[i]
                    let rows = parseInt(getValue(instance, "00280010"), 10)
                    let cols = parseInt(getValue(instance, "00280011"), 10)
                    let bitsAllocated = parseInt(getValue(instance, "00280100"), 10)
                    let bytesPerPixel = 1
                    if (bitsAllocated > 8) { bytesPerPixel = 2; }
                    else if (bitsAllocated > 16) { bytesPerPixel = 3; }
                    else if (bitsAllocated > 24) { bytesPerPixel = 4; }
                    let samplesPerPixel = parseInt(getValue(instance, "00280002"), 10)
                    if (isNaN(samplesPerPixel) || (samplesPerPixel == 0)) { samplesPerPixel = 1; }
                    var numFrames = parseInt(getValue(instance, "00280008"), 10)
                    if (isNaN(numFrames) || (numFrames == 0)) { numFrames = 1; }
                    instanceInfo.push({
                        "num_frames": numFrames,
                        "instance_num": getValue(instance, "00200013"),
                        "instance_uid": getValue(instance, "00080018"),
                        "sop_class_uid": getValue(instance, "00080016"),
                        "num_bytes": rows * cols * bytesPerPixel * samplesPerPixel,
                        "num_bytes_blob": new Array(numFrames).fill(0),
                        "cols": cols,
                        "rows": rows,
                        "reset": false,
                        "lossy": false,
                        "metadata": null
                    })
                }
                resolve(instanceInfo)
            })
            .catch(e => {
                Vue.$log.error("dicomweb qido error: "+e.message)
                reject(e)
            })
        }
        else {
            reject(new Error('worklistEntry null'))
        }
    })
}

function getSeries(worklistEntry) {
    return new Promise((resolve, reject) => {
        var seriesInfo = []
        if (worklistEntry != null) {
            const aet = store.getters.aetForGroup('/'+worklistEntry['group'])
            const qidoUrl = store.state.webServicesBaseUrl + '/DicomWebProxy/' + aet +
                "/rs/studies/"+worklistEntry.study_uid+"/series?orderby=00200011"
            axios.get(qidoUrl, {
                headers: {
                    Authorization: 'Bearer '+store.state.keycloak.token
                }
            })
            .then(result => {
                var allInstancePromises = []
                for (var i=0; i<result.data.length; i++) {
                    const series = result.data[i]
                    const seriesUid = getValue(series, "0020000E")
                    let seriesEntry = {
                        "desc": getValue(series, "0008103E"),
                        "modality": getValue(series, "00080060"),
                        "num_instances": getValue(series, "00201209"),
                        "series_num": getValue(series, "00200011"),
                        "series_uid": seriesUid,
                        "instances": []
                    }
                    seriesInfo.push(seriesEntry)
                    let instancePromise = getInstances(worklistEntry, seriesUid)
                    allInstancePromises.push(instancePromise)
                    instancePromise
                    .then(instances => {
                        seriesEntry.instances = instances
                    })
                    .catch(instanceErr => {
                        Vue.$log.error("Unable to query for instances, dicom web error: "+instanceErr.message)
                    })
                }
                Promise.allSettled(allInstancePromises)
                .then(() => {
                    resolve(seriesInfo)
                })
            })
            .catch(e => {
                Vue.$log.error("dicomweb error: "+e.message)
                reject(e)
            })
        }
        else {
            reject(new Error('worklistEntry null'))
        }
    })
}

function delay(t, val) {
    return new Promise(function(resolve) {
        setTimeout(function() {
            resolve(val);
        }, t);
    });
}

function toTimestamp(daValue, tmValue) {
    var ts = null
    try {
        if (daValue.length == 8) {
            const yy = parseInt(daValue.substr(0, 4))
            const mm = parseInt(daValue.substr(4, 2)) - 1
            const dd = parseInt(daValue.substr(6, 2))
            if (tmValue.length < 6) {
                ts = new Date(yy, mm, dd).getTime()
            }
            else {
                const hh = parseInt(tmValue.substr(0, 2))
                const mi = parseInt(tmValue.substr(2, 2))
                const ss = parseInt(tmValue.substr(4, 2))
                ts = new Date(yy, mm, dd, hh, mi, ss).getTime()
            }
        }
    }
    catch {
        ts = null
    }
    return ts
}

// Generate a unique DICOM UID
// https://stackoverflow.com/questions/58009141/how-to-convert-uuid-guid-to-oid-dicom-uid-in-javascript
// https://dicom.nema.org/dicom/2013/output/chtml/part05/sect_B.2.html
//
function generateUid() {
    var uid = '2.25.0'
    var i = 0
    while(uid.startsWith('2.25.0')) { // make sure no zero after .
        i++
        var guid = uuid.v4()
        const bigInteger = BigInt('0x'+guid.replace(/-/g, ""))
        uid = `2.25.${bigInteger.toString()}`
    }
    Vue.$log.debug("uid=["+uid+"] ("+i+" attempts)")
    return uid
}

export default {
    delay,
    downloadStudy,
    generateUid,
    getDecimalValue,
    getInstanceMetadata,
    getInstances,
    getIntegerValue,
    getPixelData,
    getPixelDataRendered,
    getSeries,
    getThumbnail,
    getValue,
    toTimestamp
}